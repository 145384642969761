import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import Modal from 'react-modal'; // Import Modal for popup

import './Journal_Calendar.css';
import { sendSimplePostRequest } from '../../Universal/Backed Calls/SimpleCallBackend';

function Journal_Calendar() {
  const [events, setEvents] = useState([]);
  const [view, setView] = useState('month');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(null);
  const [editingEvent, setEditingEvent] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    bulletPoints: [], // Array to store bullet points
    display: true,
  });
  const [newBulletPoint, setNewBulletPoint] = useState(''); // Temporary input for a new bullet point
  const [editMode, setEditMode] = useState(false);

  const openModal = (dateRange, event = null) => {
    setCurrentDate(dateRange); // Store range in currentDate
    setEditingEvent(event);
  
    setFormData(
      event
        ? {
            title: event.title,
            description: event.extendedProps?.description || '',
            bulletPoints: event.extendedProps?.bulletPoints || [],
            display: event.display !== false,
          }
        : { title: '', description: '', bulletPoints: [], display: true }
    );
    setEditMode(event ? false : true);
    setModalIsOpen(true);
  };
  

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentDate(null);
    setEditingEvent(null);
    setFormData({ title: '', description: '', bulletPoints: [], display: true });
    setNewBulletPoint('');
    setEditMode(false);
    setRecentActiveId(null)
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };


  useEffect(() => {
    handleBackend_LoadEventData()
  }, [])

  const handleBackend_LoadEventData = async () => {
    const address = 'alive/get/calendar/load_event_data';
    const response = await sendSimplePostRequest(address, null, null);

    console.log("response", response);

    if (response !== "0") {
        // Parse each event_data field
        const parsedEvents = response.map(item => {
            try {
                return JSON.parse(item.event_data); // Parse the stringified event_data
            } catch (error) {
                console.error("Error parsing event_data:", item.event_data, error);
                return null; // Skip invalid entries
            }
        }).filter(event => event); // Filter out any null (invalid) entries

        setEvents(parsedEvents); // Set cleaned events
    }
};


  useEffect(() => {
    if(events && events.length > 0){
      if(editingEvent === "delete"){
        handleBackend_DeleteEvent()
      }
      else if(editingEvent){
        handleBackend_EditEvent()
      }
      else if(recentActiveId){
        handleBackend_NewEvent()
      }
    }
  }, [events])

  const handleBackend_EditEvent = async () => {
    const address = 'alive/edit/calendar/edit_event';

    const id = editingEvent.id;
    const row_data = events.find(data => data.id === editingEvent.id);
    const data_to_pass = [
      {"id": id}, 
      {"row_data": row_data}
    ]

    const response = await sendSimplePostRequest(address, null, data_to_pass)

    if(response != "0"){
      // alert("success!!")
    }
    else{
      alert("failed to update!!")
    }

    closeModal();
    
  }

  const[recentActiveId, setRecentActiveId] = useState()

  const handleBackend_NewEvent = async () => {
    
    const address = 'alive/create/calendar/add_event';

    const id = recentActiveId;
    const row_data = events.find(data => data.id === recentActiveId);
    const data_to_pass = [
      {"id": id}, 
      {"row_data": row_data}
    ]

    console.log("data_to_pass", data_to_pass[1].row_data)

    const response = await sendSimplePostRequest(address, null, data_to_pass)

    if(response != "0"){
      // alert("success!!")
    }else{
      alert("failed to save!!")
    }

    closeModal();
  }

  const handleBackend_DeleteEvent = async () => {
    const address = 'alive/delete/calendar/delete_event';
    const id = recentActiveId;
    const data_to_pass = [
      {"id": id}, 
    ]

    const response = await sendSimplePostRequest(address, null, data_to_pass)

    if(response != "0"){
      alert("success!!")
    }

    closeModal();
  }

  //*
  const saveEvent = () => {
    if (editingEvent) {
      // Edit an existing event
      setRecentActiveId(editingEvent.id);
      const updatedEvents = events.map((event) =>
        event.id === editingEvent.id
          ? {
              ...event,
              title: formData.title,
              start: currentDate?.start || event.start, // Ensure `start` is correctly set
              end: currentDate?.end || event.end, // Ensure `end` is correctly set
              allDay: true,
              extendedProps: {
                description: formData.description,
                bulletPoints: formData.bulletPoints,
              },
              display: formData.display,
            }
          : event
      );
      setEvents(updatedEvents);
    } else {
      // Create a new event
      const temp_event_id = Date.now().toString();

      setRecentActiveId(temp_event_id);

      const newEvent = {
        id: temp_event_id,
        title: formData.title,
        start: currentDate.start,
        end: currentDate.end,
        allDay: true,
        extendedProps: {
          description: formData.description,
          bulletPoints: formData.bulletPoints,
        },
        display: formData.display,
      };
      if(events && events.length > 0){
        setEvents((prevEvents) => [...prevEvents, newEvent]);
      }
      else{
        setEvents([newEvent]);
      }
    }
    // closeModal();
  };
  
  
  


  

  const deleteEvent = () => {
    setEvents(events.filter((event) => event.id !== editingEvent.id));
    setEditingEvent("delete")
    setRecentActiveId(editingEvent.id)
    // closeModal();
  };

  // const handleDateSelect = (selectInfo) => {
  //   openModal(selectInfo.startStr);
  //   selectInfo.view.calendar.unselect();
  // };

  const handleDateSelect = (selectInfo) => {
    // Use the start and end dates from selectInfo
    const { start, end } = selectInfo;
  
    // Open modal for range selection, passing start and end
    openModal({ start, end });
    
    // Unselect the range after selection
    selectInfo.view.calendar.unselect();
  };
  

  const handleEventClick = (clickInfo) => {
    openModal(clickInfo.event.startStr, clickInfo.event);
  };

  const renderYearView = () => (
    <div className="year-view">
      {Array.from({ length: 12 }, (_, index) => {
        const month = new Date(2024, index, 1).toLocaleString('default', { month: 'long' });
        return (
          <div key={index} className="month">
            <h2>{month}</h2>
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              initialDate={`2024-${String(index + 1).padStart(2, '0')}-01`}
              headerToolbar={false}
              selectable={true}
              editable={true}
              events={events}
              select={handleDateSelect}
              eventClick={handleEventClick}
            />
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="calendar-container">
      <h1 className="calendar-title">Journal Calendar</h1>

      {/* <button onClick={()=>{console.log("events", events)}}><i>print events</i></button>
      <button onClick={()=>{console.log("formData", formData)}}><i>form data</i></button> */}

      <div className="view-toggle">
        <button onClick={() => setView('month')}>Month View</button>
        <button onClick={() => setView('year')}>Year View</button>
        <button onClick={() => window.print()}>Print</button>
      </div>
      {view === 'month' ? (
     
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          selectable={true}
          editable={true}
          events={events}
          select={handleDateSelect}
          eventClick={handleEventClick}
          // eventContent={(arg) => {
          //   const bulletPoints = arg.event.extendedProps.bulletPoints || [];
          //   const visiblePoints = bulletPoints.filter((point) => point.display);
          //   return (
          //     <div>
          //       <b>{arg.event.title}</b>
          //       {visiblePoints.length > 0 && (
          //         <ul style={{ margin: '0', padding: '0', listStyle: 'disc' }}>
          //           {visiblePoints.map((point, index) => (
          //             <li key={index} style={{ fontSize: '0.8em' }}>
          //               {point.text}
          //             </li>
          //           ))}
          //         </ul>
          //       )}
          //     </div>
          //   );
          // }}
          eventContent={(arg) => {
            const bulletPoints = arg.event.extendedProps?.bulletPoints || [];
            const visiblePoints = bulletPoints.filter((point) => point.display);
            return (
              <div>
                <b>{arg.event.title}</b>
                {visiblePoints.length > 0 && (
                  <ul style={{ margin: '0', padding: '0', listStyle: 'disc' }}>
                    {visiblePoints.map((point, index) => (
                      <li key={index} style={{ fontSize: '0.8em' }}>
                        {point.text}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            );
          }}          
        />
      ) : (
        renderYearView()
      )}

      <Modal 
        isOpen={modalIsOpen} 
        onRequestClose={closeModal} 
        className="event-modal"
        ariaHideApp={false}
      >
        <h2>{editMode ? 'Add Event' : 'Edit Event'}</h2>
        <form>
          <label>
            Title:
            <input type="text" name="title" value={formData.title} onChange={handleInputChange} required disabled={!editMode} />
          </label>
          <label>
            Description:
            <textarea name="description" value={formData.description} onChange={handleInputChange} disabled={!editMode} />
          </label>
          {/* <label>
            Display on Calendar:
            <input type="checkbox" name="display" checked={formData.display} onChange={handleInputChange} disabled={!editMode} />
          </label> */}
          <div className="bullet-points">
            {/* Add Bullet Points Section */}
            <h3>Bullet Points</h3>
            <ul>
              {formData.bulletPoints.map((point, index) => (
                <li key={index}>
                  <input
                    type="text"
                    value={point.text}
                    onChange={(e) => {
                      const updatedPoints = [...formData.bulletPoints];
                      updatedPoints[index].text = e.target.value;
                      setFormData((prev) => ({ ...prev, bulletPoints: updatedPoints }));
                    }}
                    placeholder="Enter bullet point"
                  />
                  <label>
                    Show on Calendar:
                    <input
                      type="checkbox"
                      checked={point.display}
                      onChange={(e) => {
                        const updatedPoints = [...formData.bulletPoints];
                        updatedPoints[index].display = e.target.checked;
                        setFormData((prev) => ({ ...prev, bulletPoints: updatedPoints }));
                      }}
                    />
                  </label>
                  <button
                    onClick={() => {
                      const updatedPoints = formData.bulletPoints.filter((_, i) => i !== index);
                      setFormData((prev) => ({ ...prev, bulletPoints: updatedPoints }));
                    }}
                  >
                    Delete
                  </button>
                </li>
              ))}
            </ul>

            {/* <button
              onClick={() => {
                setFormData((prev) => ({
                  ...prev,
                  bulletPoints: [...prev.bulletPoints, { text: '', display: false }],
                }));
              }}
            >
              Add Bullet Point
            </button> */}

            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setFormData((prev) => ({
                  ...prev,
                  bulletPoints: [...prev.bulletPoints, { text: '', display: false }],
                }));
              }}
            >
              Add Bullet Point
            </button>


          </div>
        </form>
        <div className="modal-buttons">
          {editMode ? (
            <button onClick={saveEvent}>Save</button>
          ) : (
            <>
              <button onClick={() => setEditMode(true)}>Edit</button>
              <button onClick={deleteEvent}>Delete</button>
            </>
          )}
          <button onClick={closeModal}>Cancel</button>
        </div>
      </Modal>
    </div>
  );
}

export default Journal_Calendar;
