import React, { useState } from 'react';
import './MainEntryPage.css';
import { sendSimplePostRequest } from '../Universal/Backed Calls/SimpleCallBackend';

//
import { useNavigate } from 'react-router-dom';


function MainEntryPage() {

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [unique_id, setUnique_id] = useState('');
    const [showLoginPopup, setShowLoginPopup] = useState(false);

    const handleBackend_TryLogin = async () => {
        const address = 'alive/user/login/authenticate';
        const data_to_pass = [
            { email },
            { password },
            { unique_id },
        ];
    
        if (email && password && unique_id) {
            const response = await sendSimplePostRequest(address, null, data_to_pass);
    
            if (response !== "0") {
                // Access the access token from the response
                const accessToken = response[0].access_token;
    
                // Create a cookie with the token and set expiry to 2 days
                const expiryDate = new Date();
                expiryDate.setTime(expiryDate.getTime() + 2 * 24 * 60 * 60 * 1000); // 2 days in milliseconds
                document.cookie = `api_token=${accessToken}; expires=${expiryDate.toUTCString()}; path=/`;
    
                navigate('/main-navigation');
                // alert('Login successful!');
                // console.log('Access token stored in cookies');
            } else {
                alert('Invalid credentials!');
            }
        } else {
            alert("All fields are required!");
        }
    };
    

    return (
        <div className="main-entry-page">
            <header className="header">
                <h1 className="app-name">Mind Instructions</h1>
                <p className="tagline">Organize your thoughts, one journal at a time.</p>
            </header>

            <div className="content">
                <button
                    className="login-button"
                    onClick={() => setShowLoginPopup(true)}
                >
                    Login
                </button>
            </div>

            {showLoginPopup && (
                <div className="login-popup">
                    <div className="popup-content">
                        <h2>Login</h2>
                        <form>
                            <div className="form-group">
                                <label>Email:</label>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Password:</label>
                                <input
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Unique ID:</label>
                                <input
                                    type="text"
                                    value={unique_id}
                                    onChange={(e) => setUnique_id(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="button-group">
                                <button
                                    type="button"
                                    className="submit-button"
                                    onClick={handleBackend_TryLogin}
                                >
                                    Login
                                </button>
                                <button
                                    type="button"
                                    className="close-button"
                                    onClick={() => setShowLoginPopup(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}

export default MainEntryPage;
