import React, {useEffect, useState} from 'react';

//importing scss
import './App.css';

//
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

//importing Components
import Home from './Components/Home/Home';
import Error404 from './Components/Errors/Error404';
import Chapter from './Components/Chapter/Chapter';
import Content from './Components/Content/Content';
import Differentiation from './Components/Differentiation/Differentiation';
import TipTap from './Components/TipTap Editor/TipTap';
import TestingLexical from './Components/TipTap Editor/TestingLexical';
import Journal_Home from './Components/Journal/Journal_Home';
import MindMap from './Components/Home/MindMap';
import MainEntryPage from './Components/Main Entry/MainEntryPage';


function App() {
  return (

    <Router>

      <Routes>

        <Route path='/' element={<MainEntryPage />} />

        {/* home page */}
        <Route path='/main-navigation' element={<Home />} />

        <Route path='/mind-map' element={<MindMap />} />

        <Route path='/daily-journal' element={<Journal_Home />} />
        
        {/* <Route path='/text-editor' element={<TipTap />} /> */}
        {/* <Route path='/text-editor-2' element={<TestingLexical />} /> */}

        {/* chapters */}
        {/* <Route path='/chapter' element={<Chapter />} /> */}

        {/* differentiation */}
        {/* <Route path='/decomposition/:tree_ring_id/:chapter_id' element={<Differentiation />} /> */}

        {/* context */}
        {/* <Route path='/decompose/:tree_ring_id/:decomposition_id/:chapter_id' element={<Content />} /> */}

          {/* context */}



        {/* error */}
        <Route path="*" element={<Error404 />} />

      </Routes>

    </Router>
  );
}

export default App;
