import React, { useEffect, useState } from 'react';

//
import './Journal_Table.css';
import { sendSimplePostRequest } from '../../Universal/Backed Calls/SimpleCallBackend';
import { getCookie, handle_MakeSureTokenPresent } from '../../Universal/AccessCookies';
import { useNavigate } from 'react-router-dom';

function TableManager(props) {

  const navigate = useNavigate(); 

  const{
    tables, setTables
  } = props;

  const [currentTable, setCurrentTable] = useState(null);
  const [isAddTablePopupOpen, setAddTablePopupOpen] = useState(false);
  const [isAddColumnPopupOpen, setAddColumnPopupOpen] = useState(false);
  const [isAddRowPopupOpen, setAddRowPopupOpen] = useState(false);

  const [newTable, setNewTable] = useState({ id: '', name: '', description: '' });
  const [newColumnName, setNewColumnName] = useState('');
  const [newRowData, setNewRowData] = useState({});

  const openAddTablePopup = () => {
    setNewTable({ id: '', name: '', description: '' });
    setAddTablePopupOpen(true);
  };


  useEffect(() => {
    handle_MakeSureTokenPresent()
    handleBackend_LoadTableData()
  }, [])

  const handle_MakeSureTokenPresent = () => {

    const getToken = getCookie("api_token");

    if (!getToken) {
        navigate('/'); // Redirect to the home page if token is missing
    }
  };


  const saveTable = async () => {
    // Use the existing ID for edits; generate a new ID only if there's no ID
    const tableId = newTable.id || Date.now().toString();
  
    const tableWithId = { ...newTable, id: tableId };
  
    if (newTable.name) {
      const existingTableIndex = tables.findIndex((table) => table.id === tableId);
  
      if (existingTableIndex !== -1) {
        // Update existing table
        const row_to_update = {
          ...tables[existingTableIndex],
          ...tableWithId,
        };
  
        const response = await handleBackend_EditTable(tableId, row_to_update);
  
        if (response === "1") {
          // alert("Table updated successfully!");
          const updatedTables = [...tables];
          updatedTables[existingTableIndex] = row_to_update;
          setTables(updatedTables); // Update frontend
        }
      } else {
        // Add new table
        const new_row = {
          ...tableWithId,
          columns: [],
          rows: [],
        };
  
        const response = await handleBackend_SaveTable(tableId, new_row);
  
        if (response === "1") {
          // alert("Table saved successfully!");
          setTables([
            ...tables,
            new_row,
          ]);
        }
      }
  
      setAddTablePopupOpen(false);
    } else {
      alert('Table ID and Name are required!');
    }
  };
  

  const deleteTable = async (id) => {

    const response = await handleBackend_DeleteTable(id);

    if(response == "1"){
      const updatedTables = tables.filter((table) => table.id !== id);
      setTables(updatedTables);
    
      // Deselect current table if it is deleted
      if (currentTable?.id === id) {
        setCurrentTable(null);
      }
    }
  };

  //!

  const handleBackend_LoadTableData = async () => {
    const address = 'alive/get/table/load_table_data';
  
    try {
      const response = await sendSimplePostRequest(address, null, null);
      console.log("Raw response:", response);
  
      if (response && Array.isArray(response) && response.length > 0) {
        // Parse each `table_data` field
        const parsedTables = response.map((item) => {
          if (item.table_data) {
            try {
              const parsedData = JSON.parse(item.table_data);
              return parsedData.row_data || parsedData; // Support nested row_data if present
            } catch (err) {
              console.error("Error parsing table_data:", item.table_data, err);
              return null; // Handle invalid JSON gracefully
            }
          }
          return null;
        }).filter((table) => table !== null); // Filter out nulls
  
        console.log("Parsed Tables:", parsedTables);
        setTables(parsedTables);
      } else {
        console.warn("No table data found in response.");
        setTables([]); // Clear tables if no data is found
      }
    } catch (error) {
      console.error("Error loading table data:", error);
    }
  };
  

  const handleBackend_SaveTable = async (idPassed, dataPassed) => {
    
    const address = 'alive/create/table/add_table';

    const data_to_pass = [
      {"id": idPassed}, 
      {"row_data": dataPassed}
    ]

    const response = await sendSimplePostRequest(address, null, data_to_pass)

    if(response != "0"){
      return "1";
    }
    else{
      alert("failed to save!!")
      return "0";
    }

  }


  const handleBackend_EditTable = async (idPassed, dataPassed) => {
    
    const address = 'alive/edit/table/edit_table';

    const data_to_pass = [
      {"id": idPassed}, 
      {"row_data": dataPassed}
    ]

    const response = await sendSimplePostRequest(address, null, data_to_pass);

    if(response != "0"){
      return "1";
    }
    else{
      alert("failed to save!!")
      return "0";
    }

  }
  

  const handleBackend_DeleteTable = async (idPassed) => {
    
    const address = 'alive/delete/table/delete_table';

    const data_to_pass = [
      {"id": idPassed}
    ]

    const response = await sendSimplePostRequest(address, null, data_to_pass);

    if(response != "0"){
      return "1";
    }
    else{
      alert("failed to save!!")
      return "0";
    }

  }
  

  //!
  const[editTableStatus, setEditTableStatus] = useState("view_only")
  const [savedChangesStatus, setSaveChangesStatus] = useState();
  const[activeTableId, setActiveTableId] = useState()

  const handleSaveChanges = async () => {
    const address = 'alive/edit/table/save/edit/progress/add_table_data';

    const temp_data = tables.find(data => data.id === activeTableId);
    ;

    console.log("activeTableId", activeTableId)
    console.log("temp_data", temp_data)
    console.log("tables", tables)

    const data_to_pass = [
      {"id": activeTableId}, 
      {"row_data": temp_data}
    ]

    const response = await sendSimplePostRequest(address, null, data_to_pass);

    if(response != "0"){
      alert("Saved Successfuly!!")
      setSaveChangesStatus(false)
    }
    else{
      alert("failed to save!! Please try again.")
      return "0";
    }

  }
  

  const selectTable = (table) => {
    setCurrentTable(table);
  };

  const openAddColumnPopup = () => {
    setNewColumnName('');
    setAddColumnPopupOpen(true);
  };

  const addColumn = () => {
    if (newColumnName && !currentTable.columns.includes(newColumnName)) {
      const updatedTable = {
        ...currentTable,
        columns: [...currentTable.columns, newColumnName],
      };
      updateTable(updatedTable);
      setAddColumnPopupOpen(false);
    } else {
      alert('Column name is required or already exists!');
    }
  };

  const openAddRowPopup = () => {
    const initialRowData = {};
    currentTable.columns.forEach((col) => {
      initialRowData[col] = '';
    });
    setNewRowData(initialRowData);
    setAddRowPopupOpen(true);
  };

  const addRow = () => {
    const updatedTable = {
      ...currentTable,
      rows: [...currentTable.rows, newRowData],
    };
    updateTable(updatedTable);
    setAddRowPopupOpen(false);
  };

  const editRow = (index, updatedRow) => {
    const updatedRows = [...currentTable.rows];
    updatedRows[index] = updatedRow;
    updateTable({ ...currentTable, rows: updatedRows });
  };

  const deleteRow = (index) => {
    const updatedRows = currentTable.rows.filter((_, rowIndex) => rowIndex !== index);
    updateTable({ ...currentTable, rows: updatedRows });
  };

  const updateTable = (updatedTable) => {
    setTables(tables.map((table) => (table.id === updatedTable.id ? updatedTable : table)));
    setCurrentTable(updatedTable);
  };





  const openEditTablePopup = (table) => {
    setNewTable(table); // Pre-fill form with the current table details
    setAddTablePopupOpen(true); // Reuse the add table popup for editing
  };



  // Function to handle editing a column name
  const editColumn = (colIndex, oldColName) => {
    const newColName = prompt("Enter new column name:", oldColName);
    if (newColName && newColName !== oldColName) {
      const updatedColumns = [...currentTable.columns];
      updatedColumns[colIndex] = newColName;

      const updatedRows = currentTable.rows.map((row) => {
        const updatedRow = { ...row };
        updatedRow[newColName] = updatedRow[oldColName];
        delete updatedRow[oldColName];
        return updatedRow;
      });

      updateTable({
        ...currentTable,
        columns: updatedColumns,
        rows: updatedRows,
      });

      setSaveChangesStatus(true)
    }
  };

  // Function to delete a column
  const deleteColumn = (colIndex) => {
    const columnName = currentTable.columns[colIndex];
    const updatedColumns = currentTable.columns.filter((_, index) => index !== colIndex);
    const updatedRows = currentTable.rows.map((row) => {
      const updatedRow = { ...row };
      delete updatedRow[columnName];
      return updatedRow;
    });

    updateTable({
      ...currentTable,
      columns: updatedColumns,
      rows: updatedRows,
    });
  };


  

  return (
    <div className="container">
      <h1 className="title">Table Manager</h1>

      <button className="button" onClick={openAddTablePopup}>
        Add Table
      </button>

      <button onClick={()=>{console.log("tables", tables)}}>print table</button>

      {/* Add Table Popup */}
      {isAddTablePopupOpen && (
        <div className="popup">
          <h2>Add Table</h2>
          {/* <input
            className="input"
            type="text"
            placeholder="Table ID"
            value={newTable.id}
            onChange={(e) => setNewTable({ ...newTable, id: e.target.value })}
          /> */}
          <input
            className="input"
            type="text"
            placeholder="Table Name"
            value={newTable.name}
            onChange={(e) => setNewTable({ ...newTable, name: e.target.value })}
          />
          <textarea
            className="input"
            type="text"
            placeholder="Table Description"
            value={newTable.description}
            onChange={(e) => setNewTable({ ...newTable, description: e.target.value })}
          />
          <div className="buttonContainer">
            <button className="button" onClick={saveTable}>
              Save
            </button>
            <button className="button cancel" onClick={() => setAddTablePopupOpen(false)}>
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* <h2 className="subTitle">Tables</h2>
      <ul className="tableList">
        {tables.map((table) => (
          <li className="tableItem" key={table.id} onClick={() => selectTable(table)}>
            {table.name} - {table.description}
          </li>
        ))}
      </ul> */}


      <h2 className="subTitle">Tables</h2>
      <ul className="tableList">
        { tables && tables.length > 0 &&
        tables.map((table) => (
          <li className="tableItem" key={table.id}
          onClick={()=>{setActiveTableId(table.id)}}
          >
            <div className="tableDetails" 
              onClick={() => {
              selectTable(table);
              }}
            >
              <span className="tableName">{table.name}</span> - <span className="tableDescription">{table.description}</span>
            </div>
            <div className="tableActions">
              <button
                className="editButton"
                onClick={(e) => {
                  e.stopPropagation();
                  openEditTablePopup(table);
                }}
              >
                Edit
              </button>
              <button
                className="deleteButton"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteTable(table.id);
                }}
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>


      {currentTable && (
        <div className='popup-2'>
        <div className="tableContainer">
          
          {
            savedChangesStatus && 
            <button
            onClick={()=>{handleSaveChanges()}}
            >
              Save Changes
            </button>
          }

          <div>

            <button
            onClick={()=>{
              setEditTableStatus("view_only")
            }}
            >
              {
                editTableStatus === "view_only" ?
                <div>
                  <b>View Only</b>
                </div>
                :
                <div>
                  View Only
                </div>
              }
            </button>

            <button
            onClick={()=>{
              setEditTableStatus("edit")
            }}
            >
              {
                editTableStatus === "edit" ?
                <div>
                  <b>Edit</b>
                </div>
                :
                <div>
                  Edit
                </div>
              }
            </button>

          </div>

          <h2>{currentTable.name}</h2>

          {editTableStatus === "view_only" && (
            <table className="table-view-only">
              <thead>
                <tr>
                  {currentTable.columns.map((col, index) => (
                    <th className="tableHeader-view-only" key={index}>
                      {col}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentTable.rows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {currentTable.columns.map((col, colIndex) => (
                      <td className="tableCell-view-only" key={colIndex}>
                        {row[col]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}


          {
            editTableStatus === "edit" && (
              <table className="table">
                <thead>
                  <tr>
                    {currentTable.columns.map((col, index) => (
                      <th className="tableHeader" key={index}>
                        <div className="headerActions">
                          <span>{col}</span>
                          <button
                            className="editButton"
                            onClick={() => editColumn(index, col)}
                          >
                            Edit
                          </button>
                          <button
                            className="deleteButton"
                            onClick={() => {
                              deleteColumn(index)
                              setSaveChangesStatus(true)
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </th>
                    ))}
                    <th className="tableHeader">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentTable.rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {currentTable.columns.map((col, colIndex) => (
                        <td className="tableCell" key={colIndex}>
                          <input
                            className="input"
                            type="text"
                            value={row[col]}
                            onChange={(e) => {
                              editRow(rowIndex, { ...row, [col]: e.target.value })
                              setSaveChangesStatus(true)
                            }}
                          />
                        </td>
                      ))}
                      <td className="tableCell">
                        <button
                          className="deleteButton"
                          onClick={() => {
                            deleteRow(rowIndex)
                            setSaveChangesStatus(true)
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )
          }


          <div className="buttonGroup">
            {
              editTableStatus === "edit" && 
              <button className="button" onClick={openAddColumnPopup}>
                Add Column
              </button>
            }
            {
              editTableStatus === "edit" && 
              <button className="button" onClick={openAddRowPopup}>
                Add Row
              </button>
            }
            <button className="deleteButton" 
              onClick={()=>{
                setCurrentTable(null)
                setEditTableStatus("view_only")
                setActiveTableId(null)
              }}
            >
              Close
            </button>
          </div>
        </div>
        </div>
      )}

      {/* Add Column Popup */}
      {isAddColumnPopupOpen && (
        <div className="popup">
          <h2>Add Column</h2>
          <input
            className="input"
            type="text"
            placeholder="Column Name"
            value={newColumnName}
            onChange={(e) => setNewColumnName(e.target.value)}
          />
          <div className="buttonContainer">
            <button className="button" 
              onClick={()=>{
                addColumn()
                setSaveChangesStatus(true)
              }}
              >
              Save
            </button>
            <button
              className="button cancel"
              onClick={() => setAddColumnPopupOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* Add Row Popup */}
      {isAddRowPopupOpen && (
        <div className="popup">
          <h2>Add Row</h2>
          {currentTable.columns.map((col, index) => (
            <div key={index} className="rowInput">
              <label>{col}: </label>
              <input
                className="input"
                type="text"
                value={newRowData[col] || ''}
                onChange={(e) =>
                  setNewRowData({ ...newRowData, [col]: e.target.value })
                }
              />
            </div>
          ))}
          <div className="buttonContainer">
            <button className="button" 
            onClick={()=>{
              addRow()
              setSaveChangesStatus(true)
            }}
            >
              Save
            </button>
            <button
              className="button cancel"
              onClick={() => setAddRowPopupOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default TableManager;
