
import axios from "axios";
import { server_baseUrl } from "../Server Side Hit Url/ServerHitUrlPart";
import { getCookie } from "../AccessCookies";

export const sendSimplePostRequest = async (addressPassed, basicData, passedData) => {

    const api_token = await getCookie("api_token");

    let response = [];

    // if(api_token){
        response = await axios.post(
            `${server_baseUrl}/${addressPassed}`, {api_token, basicData, passedData}
        );
    // }

    console.log("addressPassed", addressPassed)
    console.log("response.data", response.data)
    console.log("addressPassed", addressPassed)

    if(response.data != "0"){
        return response.data;
    }
    else{
        return "0";
    }

}


//?a sample of how you can use the data at the server:
// const handleSaveChanges = async () => {
//     const address = 'alive/create/table/save/progress/add_table_data';

//     const temp_data = events.find(data => data.id === activeTableId);
//     ;

//     const data_to_pass = [
//       {"id": activeTableId}, 
//       {"row_data": temp_data}
//     ]

//     const response = await sendSimplePostRequest(address, null, data_to_pass);

//     if(response != "0"){
//       return "1";
//     }
//     else{
//       alert("failed to save!!")
//       return "0";
//     }
// }






