import React, { useCallback, useEffect, useState } from 'react';
import ReactFlow, {
  addEdge,
  Background,
  Controls,
  MarkerType,
  applyNodeChanges,
  applyEdgeChanges,
  Handle
} from 'react-flow-renderer';
//
import { Helmet } from 'react-helmet';
import './Test.css';
import './Test_2.css';
import TipTap from '../TipTap Editor/TipTap';

//
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';


//
import { GiMagicLamp } from "react-icons/gi";
import { FaWindowClose } from "react-icons/fa";
import { FaSave } from "react-icons/fa";
import { TbChessKing } from "react-icons/tb";
import { FaChessPawn } from "react-icons/fa6";

//
import { sendSimplePostRequest } from '../Universal/Backed Calls/SimpleCallBackend';
import { getCookie } from '../Universal/AccessCookies';
import { useNavigate } from 'react-router-dom';

  

const initialEdges = [];
// const [nodes, setNodes] = useState([]); // Array to hold all nodes





const MindMap = () => {

    const navigate = useNavigate();

//   const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);
  const [selectedNode, setSelectedNode] = useState(null);
  const [newNodeData, setNewNodeData] = useState({ name: '', description: '', type: 'local' });
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [localView, setLocalView] = useState(false);
  const [localViewRoot, setLocalViewRoot] = useState(null); // Track the root node for local view
  //
  const[activeManagePopupNavigation, setActiveManagePopupNavigation] = useState("new_node")

  //
  const [brainPopup, setBrainPopup] = useState(false); // Popup visibility

  const [brainData, setBrainData] = useState([]);
  const [nodes, setNodes] = useState([]);
  
  const [activeBrainId, setActiveBrainId] = useState(null); // Tracks active brain
  const [newBrainName, setNewBrainName] = useState(''); // New brain name input
  const [newBrainDescription, setNewBrainDescription] = useState(''); // New brain description input

  //
  const[mindMapOptionsStatus, setMindMapOptionsStatus] = useState();
  //
  const [connectionFilter, setConnectionFilter] = useState("all");

  //!
  const[viewKnowledgeStatus, setViewKnowledgeStatus] = useState(false)
  // const[knowledgeData, setKnowledgeData] = useState([])
  const[knowledgeData, setKnowledgeData] = useState([])

  const[activeKnowledgePageData, setActiveKnowledgePageData] = useState(null)
  const[saveContentStatus, setSaveContentStatus] = useState(false)

  const[newKnowledge, setNewKnowledge] = useState([])
  const[addKnowledgeStatus, setAddKnowledgeStatus] = useState(false)

  //!
  const[trackModuleNodeChangesStatus, setTrackModuleNodeChangesStatus] = useState(false);
  const[trackDataLoaded_Brain, setTrackDataLoaded_Brain] = useState();


    //
    const openPopup = () => {
        document.body.style.overflow = 'hidden';
    };

    const closePopup = () => {
        document.body.style.overflow = 'auto'; 
    };

  const addNode = (newNodeData) => {
    const newNode = {
        id: `node-${Date.now()}`, // Unique identifier
        data: {
            label: (
                <div>
                    <strong>{newNodeData.name}</strong>
                    {newNodeData.description_status === "visible" && (
                        <p>{newNodeData.description}</p>
                    )}
                    <em>Type: {newNodeData.type}</em>
                </div>
            ),
            name: newNodeData.name,
            description: newNodeData.description,
            description_status: newNodeData.description_status,
            type: newNodeData.type,
        },
    };

    setNodes((prevNodes) => [...prevNodes, newNode]);
  };

  const updateNode = (id, updatedData) => {
    setNodes((prevNodes) =>
        prevNodes.map((node) =>
            node.id === id
                ? {
                    ...node,
                    data: {
                        ...node.data,
                        ...updatedData, // Merge updated data
                        label: (
                            <div>
                                <strong>{updatedData.name || node.data.name}</strong>
                                {updatedData.description_status === "visible" && (
                                    <p>{updatedData.description || node.data.description}</p>
                                )}
                                <em>Type: {updatedData.type || node.data.type}</em>
                            </div>
                        ),
                    },
                    }
                : node
            )
        );
    };



  // Node changes
  const onNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
      setTrackModuleNodeChangesStatus(true);
    },
    [] // Dependencies (add them if required)
  );
  
  // Edge changes
  const onEdgesChange = useCallback(
    (changes) => {
      setEdges((eds) => applyEdgeChanges(changes, eds));
      setTrackModuleNodeChangesStatus(true);
    },
    [] // Dependencies (add them if required)
  );

  const[activeNodeId, setActiveNodeId] = useState();

  // Handle node click to open the popup
  const onNodeClick = useCallback((event, node) => {
    setActiveManagePopupNavigation("new_node")
    setSelectedNode(node);
    setActiveNodeId(node.id)
    setSelectedModule(node.module_id)

    setEditMode(false)
    setNewNodeData({
        name: '',
        description: '',
        type: 'global', 
        description_status: 'visible'
    });



    setIsPopupOpen(true);
  }, []);

  // Input field changes for the popup form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewNodeData((prev) => ({ ...prev, [name]: value }));
  };




    const handleAddNodeOld = () => {
        if (!newNodeData.name) {
            // alert('Please provide a name for the node.');
            return;
        }
    
        const newNode = {
            id: `node-${Date.now()}`,
            brain_id: activeBrainId,  // Adding the brain_id to the node
            module_id: selectedModule,
            data: {
                label: (
                    <div>
                        <strong>{newNodeData.name}</strong>
                        <p>{newNodeData.description}</p>
                        <em>Type: {newNodeData.type}</em>
                    </div>
                ),
                name: newNodeData.name,
                description: newNodeData.description,
                type: newNodeData.type,
            },
            position: {
                x: Math.random() * 400 + 100,
                y: Math.random() * 400 + 100,
            },
            draggable: true,
        };
    
        const newEdge = {
            id: `e${selectedNode.id}-${newNode.id}`,
            source: selectedNode.id,
            target: newNode.id,
            brain_id: activeBrainId,  // Adding the brain_id to the edge
            markerEnd: { type: MarkerType.Arrow }, // Add an arrow for visual clarity
        };
    
        setNodes((nds) => [...nds, newNode]);
        setEdges((eds) => addEdge(newEdge, eds)); // Use addEdge for managing connections
        setIsPopupOpen(false);
        setNewNodeData({ name: '', description: '', type: 'local' });

        setTrackModuleNodeChangesStatus(true)
    };
    

    const handleAddNode_Latest = () => {
        if (!newNodeData.name) {
            toast.error("Please provide a name for the node.", {
                position: toast.POSITION.TOP_CENTER
              })
            return;
        }
    
        if (!selectedNode) {
            toast.error("Please provide a name for the node.", {
                position: toast.POSITION.TOP_CENTER
              })
            return;
        }
    
        // Get the parent node's position
        const parentNodePosition = selectedNode.position || { x: 0, y: 0 };
    
        // Calculate new node position near the parent node
        const newNodePosition = {
            x: parentNodePosition.x + Math.random() * 100 + 50, // Offset by -50 to +50 in x direction
            y: parentNodePosition.y + Math.random() * 100 + 50, // Offset by -50 to +50 in y direction
        };
    
        const newNode = {
            id: `node-${Date.now()}`,
            brain_id: activeBrainId, // Adding the brain_id to the node
            module_id: selectedModule,
            data: {
                label: (
                    <div>
                        <strong>{newNodeData.name}</strong>
                        <p>{newNodeData.description}</p>
                        {/* <em>Type: {newNodeData.type}</em> */}
                    </div>
                ),
                name: newNodeData.name,
                description: newNodeData.description,
                type: newNodeData.type,
            },
            position: newNodePosition, // Use calculated position
            draggable: true,
        };
    
        const newEdge = {
            id: `e${selectedNode.id}-${newNode.id}`,
            source: selectedNode.id,
            target: newNode.id,
            brain_id: activeBrainId, // Adding the brain_id to the edge
            markerEnd: { type: MarkerType.Arrow }, // Add an arrow for visual clarity
        };
    
        setNodes((nds) => [...nds, newNode]);
        setEdges((eds) => addEdge(newEdge, eds)); // Use addEdge for managing connections
        setIsPopupOpen(false);
        setNewNodeData({ name: '', description: '', type: 'local' });
    
        setTrackModuleNodeChangesStatus(true);
    };


    const CustomNode = ({ name, description, type }) => {
        // Define styles based on the type
        const isGlobal = type === "global";
    
        const containerStyle = {
            border: "1px solid",
            // borderColor: isGlobal ? "#a63c2e" : "none",
            borderColor: isGlobal ? "none" : "none",
            border: "none",
            borderRadius: "8px",
            padding: "10px",
            backgroundColor: "none",
            boxShadow: isGlobal ? "0 2px 6px rgba(0, 0, 0, 0.3)" : "0 2px 6px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
            // minWidth: "400px",
            maxWidth: "400px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        };
        
        const titleStyle = {
            fontSize: isGlobal ? "15px" : "14px",
            fontFamily: "Lato",
            color: isGlobal ? "#000000" : "#424141",
            // fontWeight: "light",
            marginBottom: "5px",
            // display: "block",
            wordWrap: "break-word",
            whiteSpace: "normal",
            letterSpacing: "0.4px",
            maxWidth: "100%", 
        };
        
        const descriptionStyle = {
            fontSize: isGlobal ? "13px" : "12px",
            fonrFamily: "Comfortaa",
            color: isGlobal ? "#474645" : "#474645",
            margin: "0",
            flexWrap: "wrap",
            wordWrap: "break-word",
            whiteSpace: "normal",    
            textAlign: "center",
            maxWidth: "100%",        
        };
        
        
    
        return (
            <div style={containerStyle}>
                <strong style={titleStyle}>{name}</strong>
                <p style={descriptionStyle}>{description}</p>
                {/* {isGlobal && (
                    <em style={{ fontSize: "10px", color: "#333" }}>🌍 Global Node</em>
                )}
                {!isGlobal && (
                    <em style={{ fontSize: "10px", color: "#333" }}>📍 Local Node</em>
                )} */}
            </div>
        );
    };
    

    const handleAddNode = () => {
        if (!newNodeData.name) {
            toast.error("Please provide a name for the node.", {
                position: toast.POSITION.TOP_CENTER,
            });
            return;
        }
    
        if (!selectedNode) {
            toast.error("Please select a parent node.", {
                position: toast.POSITION.TOP_CENTER,
            });
            return;
        }
    
        // Get the parent node's position
        const parentNodePosition = selectedNode.position || { x: 0, y: 0 };
    
        // Calculate new node position near the parent node
        const newNodePosition = {
            x: parentNodePosition.x + Math.random() * 100 + 100, // Offset by -50 to +50 in x direction
            y: parentNodePosition.y + Math.random() * 100 + 100, // Offset by -50 to +50 in y direction
        };
    
        const newNode = {
            id: `node-${Date.now()}`,
            brain_id: activeBrainId,
            module_id: selectedModule,
            data: {
                label: (
                    <CustomNode
                        name={newNodeData.name}
                        description={newNodeData.description}
                        type={newNodeData.type} // Pass the type here
                    />
                ),
                name: newNodeData.name,
                description: newNodeData.description,
                type: newNodeData.type,
            },
            position: newNodePosition,
            draggable: true,
        };
    
        const newEdge = {
            id: `e${selectedNode.id}-${newNode.id}`,
            source: selectedNode.id,
            target: newNode.id,
            brain_id: activeBrainId,
            markerEnd: { type: MarkerType.Arrow },
        };
    
        setNodes((nds) => [...nds, newNode]);
        setEdges((eds) => addEdge(newEdge, eds)); // Use addEdge for managing connections
        setIsPopupOpen(false);
        setNewNodeData({ name: "", description: "", type: "local" });
    
        setTrackModuleNodeChangesStatus(true);
    };
    


  const [selectedModule, setSelectedModule] = useState();


  // Delete selected node and connected edges
  const handleDeleteNode = () => {
    if (!selectedNode) return;

    setNodes((nds) => nds.filter((node) => node.id !== selectedNode.id));
    setEdges((eds) => eds.filter((edge) => edge.source !== selectedNode.id && edge.target !== selectedNode.id));
    setSelectedNode(null);
    setIsPopupOpen(false);

    setTrackModuleNodeChangesStatus(true)
  };

  // View only local nodes
  const handleViewOnlyLocal = () => {
    setLocalView(true);
    setLocalViewRoot(selectedNode); // Set the selected node as the root for local view
  };

  // Remove local view filter
  const handleRemoveLocalView = () => {
    setLocalView(false);
    setLocalViewRoot(null);
  };

  const[hideParentsView, setHideParentsView] = useState()

  const handleHideParents = () => {
    setHideParentsView(true);
    setLocalView(false);
  };

  const handleRemoveHideParents = () => {
    setHideParentsView(false);
  };



    // Recursive function to get all downstream locals
    const getLocalBranch = (nodeId) => {
        const directEdges = edges.filter((edge) => edge.source === nodeId);
        const localNodes = directEdges
          .map((edge) => nodes.find((node) => node.id === edge.target && node.data.type === 'local'))
          .filter(Boolean);
    
        return localNodes.reduce(
          (acc, localNode) => [...acc, localNode, ...getLocalBranch(localNode.id)],
          localNodes
        );
    };

    const[moduleDisplayStatus, setModuleDisplayStatus] = useState("all");



    const displayedNodes = (() => {
        if (!nodes || !Array.isArray(nodes)) {
            // Handle cases where nodes is null, undefined, or not an array
            return [];
        }
    
        // Ensure nodes are filtered by active brain
        const brainSpecificNodes = nodes.filter((node) => node.brain_id === activeBrainId);
    
        if (!activeBrainId) {
            // Handle the case where no brain is selected
            return [];
        }
    
        if (hideParentsView && selectedNode && brainSpecificNodes.length > 0) {
            // Display the selected node and its downstream nodes within the active brain
            const downstreamNodes = brainSpecificNodes.filter((node) =>
                edges.some((edge) => edge.source === selectedNode.id && edge.target === node.id)
            );
            return [selectedNode, ...downstreamNodes];
        }
    
        if (localView && localViewRoot) {
            // Display the localViewRoot and its immediate children (local branches) within the active brain
            const localBranchNodes = getLocalBranch(localViewRoot.id).filter(
                (node) => node.brain_id === activeBrainId
            );
            return [localViewRoot, ...localBranchNodes];
        }
    
        if (selectedModule && moduleDisplayStatus === "single") {
            // Display only the selected module within the active brain
            return brainSpecificNodes.filter((node) => node.module_id === selectedModule);
        }
    
        if (!selectedModule && moduleDisplayStatus === "all") {
            // Display all nodes within the active brain
            return brainSpecificNodes;
        }
    
        // Default to showing all nodes within the active brain
        return brainSpecificNodes;
    })();
    
    

  
    const displayedEdges = (() => {
        if ((localView && localViewRoot) || (hideParentsView && selectedNode)) {
            const nodeIds = displayedNodes.map((node) => node.id);
    
            // Filter edges to include only those whose source and target are in `displayedNodes`
            const filteredEdges = edges.filter(
                (edge) =>
                    nodeIds.includes(edge.source) &&
                    nodeIds.includes(edge.target) &&
                    nodes.some(
                        (node) =>
                            (node.id === edge.source || node.id === edge.target) &&
                            node.brain_id === activeBrainId // Ensure edge belongs to active brain
                    )
            );
    
            return filteredEdges;
        }
    
        // Default: show all edges belonging to the active brain
        return edges.filter(
            (edge) =>
                nodes.some(
                    (node) =>
                        (node.id === edge.source || node.id === edge.target) &&
                        node.brain_id === activeBrainId
                )
        );
    })();



    const onConnect = (connection) => {
        // Ensure the source and target belong to the same active brain
        const sourceNode = nodes.find((node) => node.id === connection.source);
        const targetNode = nodes.find((node) => node.id === connection.target);
    
        if (sourceNode?.brain_id !== activeBrainId || targetNode?.brain_id !== activeBrainId) {
            toast.error("Cannot connect nodes from different brains.", {
                position: toast.POSITION.TOP_CENTER
            })
            return;
        }
    
        // Add the new connection to the edges state with the brain_id
        setEdges((prevEdges) => [
            ...prevEdges,
            {
                id: `${connection.source}-${connection.target}`,
                ...connection,
                brain_id: activeBrainId,  // Adding the brain_id to the edge
            },
        ]);

        setTrackModuleNodeChangesStatus(true);
    };
    
    


    const [editMode, setEditMode] = useState(false);

    // Handle save when editing a node
    const handleSaveNode = () => {
        if (!selectedNode) {
        console.error('No node selected to save changes.');
        return;
        }
    
        // Only update the node if there is a change in the data
        // ?000012 - here you can change  
        const updatedNode = {
            ...selectedNode,
            data: {
                ...selectedNode.data,
                label: (
                    // <CustomNode 
                    
                    // />

                    <CustomNode
                        name={newNodeData.name || selectedNode.data.name}
                        description={
                            newNodeData.description ||
                            selectedNode.data.description
                        }
                        descriptionStatus={
                            newNodeData.description_status !== "hidden"
                                ? newNodeData.description_status
                                : selectedNode.data.description_status
                        }
                        type={newNodeData.type || selectedNode.data.type}
                    />


                    // <div>
                    //     <strong>{newNodeData.name || selectedNode.data.name}</strong>
                    //     { // Conditionally render description
                    //     (newNodeData.description_status !== "hidden" || 
                    //     selectedNode.data.description_status !== "hidden") && (
                    //         <p>{newNodeData.description || selectedNode.data.description}</p>
                    //     )
                    //     }
                    //     {/* <em>Type Type Type 2: {newNodeData.type || selectedNode.data.type}</em> */}
                    // </div>
                ),
                // Update name and type
                name: newNodeData.name || selectedNode.data.name,
                type: newNodeData.type || selectedNode.data.type,
                // Include description status separately
                description: newNodeData.description || selectedNode.data.description,
                description_status: newNodeData.description_status || selectedNode.data.description_status,
            },
        };

    
        // Update the nodes state with the updated node
        setNodes((nds) => nds.map((node) => (node.id === selectedNode.id ? updatedNode : node)));
    
        // Close the popup and reset form
        setIsPopupOpen(false);
        setNewNodeData({ name: '', description: '', type: 'local' });

        setTrackModuleNodeChangesStatus(true);
    };
  
      
      

    //!
    const[modulePopup, setModulePopup] = useState(false);

    const removeModule = (module_id) => {
        setNodes(nodes.filter((node) => node.module_id !== module_id));
    };


    // Form inputs for the new module
    const [newModule, setNewModule] = useState({
        module_id: "",
        label: "",
        description: "",
    });


    // Add the new module to the nodes
    const addModule = async () => {
        const uniqueId = Date.now().toString();
        const newNode = {
            id: uniqueId,
            module_id: uniqueId,
            brain_id: activeBrainId,
            node_type: "module",
            type: 'input',
            data: {
                ...newModule, // Spread all newModule fields (module_id, label, description)
                label: (
                    <div>
                        <strong>{newModule.label}</strong>
                        {newModule.description && (
                            <p>{newModule.description}</p>
                        )}
                        <em>Type: {newModule.type || "global"}</em>
                    </div>
                ),
                name: newModule.label,
                type: newModule.type || "global",
                description: newModule.description,
                description_status: "visible", // Always visible for new modules
            },
            position: { x: 250 + nodes.length * 50, y: 250 }, // Position logic
            draggable: true,
        };

        // const response = await handleBackend_AddModule(uniqueId, newNode);
        
        // if(response === "1"){
            setNodes([...nodes, newNode]);
            setNewModule({ module_id: "", label: "", description: "" }); // Reset form
            setModulePopup(false); // Close popup
        // }

        setTrackModuleNodeChangesStatus(true)
    };


    //!

    const handleBackend_SaveModuleNodeChanges = async () => {

        const address = 'alive/create/mind_map/modules/nodes/add_modules_data';

        const nodeDataPassed = nodes.filter((data) => data.brain_id === activeBrainId);
        const edgeDataPassed = edges.filter((data) => data.brain_id === activeBrainId);

        const data_to_pass = [
        {"id": activeBrainId}, 
        {"node_data": nodeDataPassed},
        {"edge_data": edgeDataPassed}
        ]

        console.log("node_data", nodeDataPassed)
        console.log("edge_data", edgeDataPassed)

        const response = await sendSimplePostRequest(address, null, data_to_pass);

        if(response != "0"){
            setTrackModuleNodeChangesStatus(false);
            toast.success("Success", {
                position: toast.POSITION.TOP_CENTER
            })
        }
        else{
            toast.error("failed to save!! Please try again.", {
                position: toast.POSITION.TOP_CENTER
            })
        }
    }

 

    const [editingBrainId, setEditingBrainId] = useState(null);
    
    const addBrain = async () => {
        if (newBrainName.trim() !== '' && newBrainDescription.trim() !== '') {
            const unique_id = editingBrainId ? editingBrainId : Date.now().toString();
            const newBrain = {
                id: unique_id,
                name: newBrainName,
                description: newBrainDescription,
            };
            
            let response;

            if(editingBrainId){
                response = await handleBackend_EditBrain(unique_id, newBrain);
            }
            else{
                response = await handleBackend_SaveBrain(unique_id, newBrain);
            }
    
            if (response === "1") {
                if (editingBrainId) {
                    // Update existing brain
                    setBrainData((prevData) =>
                        prevData.map((brain) =>
                            brain.id === editingBrainId ? newBrain : brain
                        )
                    );
                    setEditingBrainId(null);
                } else {
                    // Add a new brain
                    setBrainData((prevData) => [...prevData, newBrain]);
                }
    
                // Reset the input fields and close the popup
                setNewBrainName('');
                setNewBrainDescription('');
                setBrainPopup(false);
            }
        }
    };


    const deleteBrain = async (id) => {
        const response = await handleBackend_DeleteBrain(id);

        if(response === "1"){
            setBrainData((prevData) => prevData.filter((brain) => brain.id !== id));
            if (activeBrainId === id) setActiveBrainId(null);
        }
    };    
    
    useEffect(()=>{
        handle_MakeSureTokenPresent()
        handleBackend_LoadBrainData();
    }, [])

    const handle_MakeSureTokenPresent = () => {

        const getToken = getCookie("api_token");
    
        if (!getToken) {
            navigate('/');
        }
    };

    // useEffect(()=>{
    //     handleBackend_LoadBrainData();
    // }, [trackDataLoaded_Brain])

    const handleBackend_LoadBrainData = async () => {
        const address = 'alive/get/mind_map/brain/load_brain_data';

        // const data_to_pass = [
        // {"id": idPassed}, 
        // {"row_data": dataPassed}
        // ]

        const response = await sendSimplePostRequest(address, null, null);

        console.log("response - handleBackend_LoadBrainData", response)

        if(response != "0"){
            setBrainData(response)
        }
        else{
        toast.error("failed to save!! Please try again.", {
            position: toast.POSITION.TOP_CENTER
        })
        return "0";
        }
    }


    const OLD_handleBackend_LoadModuleData = async (idPassed) => {
        const address = 'alive/get/mind_map/brain/module/node/load_module_data';
    
        const data_to_pass = [{ "id": idPassed }];
    
        const response = await sendSimplePostRequest(address, null, data_to_pass);
    
        console.log("Response - handleBackend_LoadModuleData", response);
    
        if (response !== "0") {
            // Extract and parse brain_modules_data for the active brain
            // const activeBrainData = response.find((brain) => brain.id === idPassed);
            const activeBrainData = response;
    
            if (activeBrainData) {
                // Access `brain_modules_data` directly
                const rawModulesData = activeBrainData.brain_modules_data;
            
                console.log("rawModulesData", rawModulesData);
            
                let parsedModulesData = [];
            
                try {
                    // Parse the JSON data only if it's not null or undefined
                    parsedModulesData = rawModulesData ? JSON.parse(rawModulesData) : [];
                } catch (error) {
                    console.error("Error parsing brain_modules_data:", error);
                    toast.error("Failed to load module data. Please check the backend response.", {
                        position: toast.POSITION.TOP_CENTER
                    })
                    return "0";
                }
            
                console.log("activeBrainData", activeBrainData);
                console.log("parsedModulesData", parsedModulesData);
            
                // Set nodes to the parsed data
                setNodes(parsedModulesData);
                setTrackDataLoaded_Brain(idPassed); // Indicate the data was successfully loaded
            } 
        } else {
            toast.error("Failed to load brain data!! Please try again.", {
                position: toast.POSITION.TOP_CENTER
            })
            return "0";
        }
    };

    //?
    //!
    


    const reconstructLabel = (serializedLabel) => {
        if (serializedLabel && typeof serializedLabel === "object") {
            // Extract useful properties
            const name = serializedLabel?.props?.children?.[0]?.props?.children || "Unnamed";
            const description = serializedLabel?.props?.children?.[1]?.props?.children;
            const type = serializedLabel?.props?.children?.[2]?.props?.children?.[1] || "Unknown";
    
            // Recreate the React element for the label
            return (
                <div>
                    <strong>{name}</strong>
                    {
                        description !== "No description provided" &&
                        <p>{description}</p>
                    }
                    {/* <em>Type: {type}</em> */}
                </div>
            );
        }
    
        // Handle cases where serializedLabel is invalid
        console.warn("Invalid label structure:", serializedLabel);
        return <div>Invalid Label</div>;
    };


    // const handleBackend_LoadModuleData = async (idPassed) => {
    //     const address = "alive/get/mind_map/brain/module/node/load_module_data";
    //     const data_to_pass = [{ id: idPassed }];
        
    //     try {
    //         const response = await sendSimplePostRequest(address, null, data_to_pass);
            
    //         console.log("Full Response:", response);
            
    //         // Parse the node and edge data from the response
    //         const nodesData = JSON.parse(response[0].node);
    //         const edgesData = JSON.parse(response[0].edge);
    
    //         // Now nodesData and edgesData are arrays of node and edge objects
    //         console.log("Parsed Node Data:", nodesData);
    //         console.log("Parsed Edge Data:", edgesData);
    
    //         // You can now use nodesData and edgesData to update your UI
    //         // Example: Setting the state with the parsed data
    //         setNodes(nodesData);
    //         setEdges(edgesData);
    
    //         // Additional logic to render the nodes and edges
    //     } catch (error) {
    //         console.error("Error loading module data:", error);
    //     }
    // };
    
    
    
    const handleBackend_LoadModuleData_OLD3 = async (idPassed) => {
        const address = "alive/get/mind_map/brain/module/node/load_module_data";
        const data_to_pass = [{ id: idPassed }];
    
        try {
            const response = await sendSimplePostRequest(address, null, data_to_pass);
    
            console.log("Full Response:", response);
    
            if (response !== "0" && response?.length > 0) {
                try {
                    const parsedData = JSON.parse(response[0]?.brain_modules_data || "[]"); // Parse safely with fallback
    
                    console.log("Parsed Data:", parsedData);
    
                    if (Array.isArray(parsedData)) {
                        const reconstructedNodes = parsedData.map((node) => {
                            const label =
                                typeof node.data.label === "object"
                                    ? reconstructLabel(node.data.label) // Reconstruct React label
                                    : node.data.label; // Keep valid strings as-is
    
                            return {
                                ...node,
                                data: {
                                    ...node.data,
                                    label, // Replace with reconstructed or existing label
                                },
                            };
                        });
    
                        console.log("Reconstructed Nodes:", reconstructedNodes);
                        setNodes(reconstructedNodes);
                    } else {
                        console.error("Parsed data is not an array:", parsedData);
                        setNodes([]); // Fallback to an empty array
                    }
                } catch (parseError) {
                    console.error("Error parsing response data:", parseError);
                    setNodes([]); // Fallback to an empty state
                }
            } else {
                console.warn("Unexpected or empty response:", response);
                setNodes([]); // Fallback for empty response
            }
        } catch (error) {
            console.error("Error loading module data:", error);
            setNodes([]); // Fallback for failed request
        }
    
        setTrackDataLoaded_Brain(idPassed); // Notify that data loading is complete
    };
    
    


    const handleBackend_LoadModuleData_mstrece = async (idPassed) => {
        const address = "alive/get/mind_map/brain/module/node/load_module_data";
        const data_to_pass = [{ id: idPassed }];

        try {
            const response = await sendSimplePostRequest(address, null, data_to_pass);
    
            console.log("Full Response:", response);
    
            if (response !== "0" && response && response.length > 0) {
                // Parse data safely
                const parsedData = JSON.parse(response[0].node);
    
                console.log("Parsed Data:", parsedData);
    
                if (Array.isArray(parsedData)) {
                    // Reconstruct nodes with valid labels
                    const reconstructedNodes = parsedData.map((node) => {
                        // If label contains React elements, replace with valid content
                        const label =
                            typeof node.data.label === "object"
                                ? reconstructLabel(node.data.label) // Reconstruct React label
                                : node.data.label; // Keep valid strings as-is
    
                        return {
                            ...node,
                            data: {
                                ...node.data,
                                label, // Replace with valid label
                            },
                        };
                    });
    
                    console.log("Reconstructed Nodes:", reconstructedNodes);
                    setNodes(reconstructedNodes);
                } else {
                    console.error("Parsed data is not an array:", parsedData);
                    setNodes([]); // Fallback to an empty array
                }
            }
        } catch (error) {
            console.error("Error loading module data:", error);
        }
    
        setTrackDataLoaded_Brain(idPassed);
    };


    const handleBackend_LoadModuleData = async (idPassed) => {
        const address = "alive/get/mind_map/brain/module/node/load_module_data";
        const data_to_pass = [{ id: idPassed }];
    
        try {
            const response = await sendSimplePostRequest(address, null, data_to_pass);
    
            console.log("Full Response:", response);
    
            if (response !== "0" && response && response.length > 0) {
                // Parse data safely
                const parsedData = JSON.parse(response[0].node);
                const parsedEdges = JSON.parse(response[0].edge);  // Parse the edges
    
                console.log("Parsed Data:", parsedData);
                console.log("Parsed Edges:", parsedEdges);
    
                if (Array.isArray(parsedData)) {
                    // Reconstruct nodes with valid labels
                    const reconstructedNodes = parsedData.map((node) => {
                        // If label contains React elements, replace with valid content
                        const label =
                            typeof node.data.label === "object"
                                ? reconstructLabel(node.data.label) // Reconstruct React label
                                : node.data.label; // Keep valid strings as-is
    
                        return {
                            ...node,
                            data: {
                                ...node.data,
                                label, // Replace with valid label
                            },
                        };
                    });
    
                    console.log("Reconstructed Nodes:", reconstructedNodes);
                    setNodes(reconstructedNodes);
                } else {
                    console.error("Parsed data is not an array:", parsedData);
                    setNodes([]); // Fallback to an empty array
                }
    
                if (Array.isArray(parsedEdges)) {
                    // Add edges to the state
                    const reconstructedEdges = parsedEdges.map((edge) => ({
                        ...edge,
                        markerEnd: edge.markerEnd || { type: "arrow" }, // Ensure the marker is present
                    }));
    
                    console.log("Reconstructed Edges:", reconstructedEdges);
                    setEdges(reconstructedEdges);  // Update edges state
                } else {
                    console.error("Parsed edges are not an array:", parsedEdges);
                    setEdges([]); // Fallback to an empty array
                }
            }
        } catch (error) {
            console.error("Error loading module data:", error);
        }
    
        setTrackDataLoaded_Brain(idPassed);
    };
    
    
    

    
    const handleBackend_SaveBrain = async (idPassed, dataPassed) => {
        const address = 'alive/create/mind_map/brain/add_brain';

        const data_to_pass = [
        {"id": idPassed}, 
        {"row_data": dataPassed}
        ]

        const response = await sendSimplePostRequest(address, null, data_to_pass);

        if(response != "0"){
        return "1";
        }
        else{
        toast.error("failed to save!! Please try again.", {
            position: toast.POSITION.TOP_CENTER
        })
        return "0";
        }
    }

    const handleBackend_EditBrain = async (idPassed, dataPassed) => {
        const address = 'alive/edit/mind_map/brain/edit_brain';

        const data_to_pass = [
        {"id": idPassed}, 
        {"row_data": dataPassed}
        ]

        const response = await sendSimplePostRequest(address, null, data_to_pass);

        if(response != "0"){
        return "1";
        }
        else{
        toast.error("failed to save!! Please try again.", {
            position: toast.POSITION.TOP_CENTER
        })
        return "0";
        }
    }

    
    const handleBackend_DeleteBrain = async (idPassed, dataPassed) => {
        const address = 'alive/delete/mind_map/delete_brain';

        const data_to_pass = [
        {"id": idPassed}
        ]

        const response = await sendSimplePostRequest(address, null, data_to_pass);

        if(response != "0"){
        return "1";
        }
        else{
        toast.error("failed to save!! Please try again.", {
            position: toast.POSITION.TOP_CENTER
        })
        return "0";
        }
    }


    const handleEdgeClick = (edgeToRemove) => {
        // Confirm before deleting (optional)
        if (window.confirm(`Delete connection from ${edgeToRemove.source} to ${edgeToRemove.target}?`)) {
            setEdges((prevEdges) =>
                prevEdges.filter(
                    (edge) => edge.source !== edgeToRemove.source || edge.target !== edgeToRemove.target
                )
            );
        }
        setTrackModuleNodeChangesStatus(true)
    };
    



    //
    const addKnowledge = async () => {

        const uniqueId = Date.now();

        const newData = {
            r_module_id: selectedModule,
            r_brain_id: activeBrainId,
            r_node_id: activeNodeId,
            
            k_id: uniqueId,
            name: newKnowledge.name,
            description: newKnowledge.description,
            information: null
        };
        
        const response = await handleBackend_addKnowledge(uniqueId, newData)

        if(response === "1"){
            setKnowledgeData([...knowledgeData, newData]);
            setNewKnowledge({ k_id: "", name: "", description: "" }); // Reset form
            setAddKnowledgeStatus(false); // Close popup
        }
    };

    const handleSaveKnowledgeContent = async (active) => {

        const response = await handleBackend_SaveChangesKnowledge(active.k_id, active)

        if(response === "1"){
            setKnowledgeData((prevKnowledgeData) =>
            prevKnowledgeData.map((knowledge) =>
                knowledge.k_id === active.k_id
                ? { ...knowledge, ...active } // Replace row
                : knowledge
            )
            );
            setSaveContentStatus(false)
            toast.success("Success.", {
                position: toast.POSITION.TOP_CENTER
            })
        }
    };


    //!!

    //*Add K
    const handleBackend_addKnowledge = async (idPassed, dataPassed) => {
   
        const address = 'alive/create/knowledge/add_knowledge'

        const data_to_pass = [
            {"id": idPassed}, 
            {"row_data": dataPassed}, 
        ];

        const response = await sendSimplePostRequest(address, null, data_to_pass)

        if (response != "0") {
            return "1";
        }else{
            toast.error("Opps, some error...not added. Please try again..", {
                position: toast.POSITION.TOP_CENTER
            })
        }
    };
    

    //*Add K
    const handleBackend_SaveChangesKnowledge = async (idPassed, dataPassed) => {

        const address = 'alive/edit/knowledge/save_changes_knowledge';

        const data_to_pass = [
            {"id": idPassed}, 
            {"row_data": dataPassed}, 
        ];

        const response = await sendSimplePostRequest(address, null, data_to_pass)

        if (response != "0") {
            return "1";
        }else{
            toast.error("Opps, some error...not added. Please try again..", {
                position: toast.POSITION.TOP_CENTER
            })
        }
    };

    //*Delete
    const handleBackend_deleteKnowledge = async (knowledgeId) => {
   
        try {
            const address = 'alive/delete/knowledge/delete_knowledge';

            const data_to_pass = [
                {"id": knowledgeId}, 
                {"brain_id": activeBrainId}, 
            ];

            const response = await sendSimplePostRequest(address, null, data_to_pass)
    
            if (response != "0") {
                setKnowledgeData((prevData) =>
                    prevData.filter((data) => data.k_id !== knowledgeId)
                );
            } else {
                console.error("Failed to delete knowledge. Please try again.");
            }
        } catch (error) {
            console.error("Error deleting knowledge:", error);
        }
    };

    //*Knowledge
    const[trackKnowledgeDataStatus, setTrackKnowledgeDataStatus] = useState()

    const handleBackend_LoadKnowledgeData = async() => {

        const address = 'alive/get/knowledge/load_node_knowledge_data';

        const data_to_pass = [
            {"brain_id": activeBrainId}, 
            {"node_id": activeNodeId}, 
        ];

        const response = await sendSimplePostRequest(address, null, data_to_pass)

        if (response != "0" && response && response.length > 0) {
            setKnowledgeData(response)
            setTrackKnowledgeDataStatus({
                "brain_id": activeBrainId,
                "node_id": activeNodeId
            })
        }else{
            toast.error("Opps, some error...not added. Please try again..", {
                position: toast.POSITION.TOP_CENTER
            })
        }

    }
    



    // useEffect(() => {

    // }, [viewKnowledgeStatus])




  return (
    <div style={{ height: '100vh', width: '100%' }}>



<Helmet>

<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
<link href="https://fonts.googleapis.com/css2?family=Assistant:wght@200&family=Comfortaa:wght@300&family=Finlandica&family=Karla:wght@300&family=Kodchasan:wght@200&family=Lato:wght@300&family=Maven+Pro&family=Mitr:wght@300&family=Montserrat:wght@300&family=Nanum+Gothic&family=Nunito+Sans:opsz,wght@6..12,300&family=Oxygen&family=Poppins:wght@300&family=Quicksand:wght@300&family=Rubik&family=Satisfy&family=Space+Grotesk&family=Work+Sans:wght@300&family=Ysabeau+Office:wght@200&display=swap" rel="stylesheet" />

<link href="https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" />

<link href="https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&display=swap" rel="stylesheet" />

</Helmet>


        <div className="mindmap-options-button-container-MindMap">

            {
                trackModuleNodeChangesStatus &&
                <button
                className="mindmap-options-button-MindMap"
                onClick={() => {
                    if(activeBrainId){
                        handleBackend_SaveModuleNodeChanges()
                    }
                    else{
                        toast.success("select a brain...", {
                            position: toast.POSITION.TOP_CENTER
                        })
                    }
                }}
                >
                    <FaSave />
                </button>
            }    

            <button
                className="mindmap-options-button-MindMap"
                onClick={() => {
                setMindMapOptionsStatus("manage");
                }}
            >
                <GiMagicLamp />
            </button>

            {/*//! 2 */}
            <div className="mindmap-options-container-MindMap"
            onMouseOver={() => setMindMapOptionsStatus("brain")}
            >
                <button
                    className="mindmap-options-button-MindMap"
                    onClick={() => {setMindMapOptionsStatus("brain")}}
                    onMouseOver={() => setMindMapOptionsStatus("brain")}
                >
                    {/* <GiMagicLamp /> */}
                    <TbChessKing />
                </button>

                {brainData && brainData.length > 0 && mindMapOptionsStatus === "brain" && nodes && (
                <div
                className="nodes-list-MindMap"
                onMouseOver={() => setMindMapOptionsStatus("brain")}
                onMouseOut={() => setMindMapOptionsStatus(null)}
                >
                    {/* <button
                    onClick={()=>{setMindMapOptionsStatus(null)}}
                    >
                        <FaWindowClose />
                    </button> */}

                    {brainData.length > 0 && (
                        <div className="brain-list-MindMap">
                        {brainData.map((data, index) => (
                            <button
                            // className="brain-button-MindMap"
                            className="node-button-MindMap"
                            key={index}
                            onClick={() => {
                                setActiveBrainId(data.id)
                                if(trackDataLoaded_Brain !== data.id){
                                    handleBackend_LoadModuleData(data.id)
                                }
                            }}
                            >
                            {data.name}
                            </button>
                        ))}
                        </div>
                    )}
                </div>
                )}

            </div>    



            {/* //! 3 */}
            <div className="mindmap-options-container-MindMap"
            onMouseOver={() => setMindMapOptionsStatus("module")}
            >
                <button
                    className="mindmap-options-button-MindMap"
                    onClick={() => setMindMapOptionsStatus("module")}
                    onMouseOver={() => setMindMapOptionsStatus("module")}
                >
                    {/* <GiMagicLamp /> */}
                    <FaChessPawn />
                </button>

                {/* Floating Options */}
                {activeBrainId && mindMapOptionsStatus === "module" && nodes && (
                    <div
                    className="nodes-list-MindMap"
                    onMouseOver={() => setMindMapOptionsStatus("module")}
                    onMouseOut={() => setMindMapOptionsStatus(null)}
                    >
                    {/* <button
                        className="close-options-button-MindMap"
                        onClick={() => setMindMapOptionsStatus(null)}
                    >
                        <FaWindowClose />
                    </button> */}

                    <button
                        className="all-modules-button-MindMap"
                        onClick={() => setModuleDisplayStatus("all")}
                    >
                        <b>All Modules</b>
                    </button>

                    {nodes.map((data, index) => {
                        if (data.node_type === "module" && data.brain_id === activeBrainId) {
                        return (
                            <button key={index}
                            className="node-button-MindMap"
                            onClick={() => {
                                setSelectedModule(data.module_id);
                                setModuleDisplayStatus("single");
                            }}
                            >
                            <b>{data.data.name}</b>
                            </button>
                        );
                        }
                    })}
                    </div>
                )}
            </div>

        </div>


        {/*//! */}
        { mindMapOptionsStatus === "manage" &&
  
        <div className='popup-overlay-Main-MindMap'>
        <div className='popup-Main-MindMap'>    

            <button
            onClick={()=>{setMindMapOptionsStatus(false)}}
            >
                <FaWindowClose />
            </button>

            {/* Button to open the Brain Popup */}
            <div className="button-container-MindMap">
                <button className="add-brain-button-MindMap" onClick={() => {
                    setBrainPopup(true)
                    setEditingBrainId(null)
                    }}>
                    Add Brain +
                </button>
            </div>

            {/* Brain Popup */}
            {brainPopup && (
                <div className="popup-overlay-MindMap">
                    <div className="popup-MindMap">
                        <button className="close-button-MindMap" 
                            onClick={() => {
                                setBrainPopup(false)
                                setEditingBrainId(null)
                            }}
                        >
                            Close
                        </button>
                        <h2 className="popup-header-MindMap">Add Brain</h2>
                        <div className="form-group-MindMap">
                            <label className="label-MindMap">Brain Name:</label>
                            <input
                                className="input-MindMap"
                                type="text"
                                value={newBrainName}
                                onChange={(e) => setNewBrainName(e.target.value)}
                                placeholder="Enter Brain Name"
                            />
                        </div>
                        <div className="form-group-MindMap">
                            <label className="label-MindMap">Brain Description:</label>
                            <textarea
                                className="input-MindMap"
                                type="text"
                                value={newBrainDescription}
                                onChange={(e) => setNewBrainDescription(e.target.value)}
                                placeholder="Enter Brain Description"
                            />
                        </div>
                        <div className="button-container-MindMap">
                            <button className="save-brain-button-MindMap" onClick={addBrain}>Save Brain</button>
                        </div>
                    </div>
                </div>
            )}

            {/* List Brains with Edit and Delete */}
            {brainData.length > 0 && (
                <div className="brain-list-MindMap">
                    {brainData.map((data, index) => (
                        <div key={index} className="brain-item-MindMap">
                            <button
                                className="brain-button-MindMap"
                                onClick={() => {
                                    setActiveBrainId(data.id)
                                    if(trackDataLoaded_Brain !== data.id){
                                        handleBackend_LoadModuleData(data.id)
                                    }
                                }}
                            >
                                {activeBrainId === data.id ? (
                                    <div style={{ color: "red" }}>
                                        <b>{data.name}</b> - {data.description}
                                    </div>
                                ) : (
                                    <div>
                                        <b>{data.name}</b> - {data.description}
                                    </div>
                                )}
                            </button>
                            {/* Edit Button */}
                            <button
                                className="edit-brain-button-MindMap"
                                onClick={() => {
                                    setBrainPopup(true);
                                    setNewBrainName(data.name);
                                    setNewBrainDescription(data.description);
                                    setEditingBrainId(data.id); // New state to track the brain being edited
                                }}
                            >
                                Edit
                            </button>
                            {/* Delete Button */}
                            <button
                                className="delete-brain-button-MindMap"
                                onClick={() => deleteBrain(data.id)} // Define this function to handle delete
                            >
                                Delete
                            </button>
                        </div>
                    ))}
                </div>
            )}


            {/*//!Module  */}
            {activeBrainId && (
                <div className="module-buttons-MindMap">
                <button
                    className="add-module-button-MindMap"
                    onClick={() => setModulePopup(true)}
                >
                    Add Module +
                </button>
                </div>
            )}

            {activeBrainId && nodes && (
                <div className="nodes-list-MindMap##">
                    <button
                        className="all-modules-button-MindMap"
                        onClick={() => setModuleDisplayStatus("all")}
                    >
                        {
                            moduleDisplayStatus === "all" ?
                            <div>
                                <b>All Modules</b>
                            </div>
                            :
                            <div>
                                All Modules
                            </div>
                        }
                    </button>
                    
                    {nodes.map((data, index) => {
                        if (data.node_type === "module" && data.brain_id === activeBrainId) {
                        return (
                            <button
                            className="node-button-MindMap"
                            key={index}
                            onClick={() => {
                                setSelectedModule(data.module_id);
                                setModuleDisplayStatus("single");
                            }}
                            >
                                {
                                    (selectedModule === data.module_id && moduleDisplayStatus === "single") ? 
                                    <div>
                                        <b>{data.data.name}</b>
                                    </div>
                                    :
                                    <div>
                                        {data.data.name}
                                    </div>
                                }
                            </button>
                        );
                        }
                    })}
                </div>
            )}

            {modulePopup && (
                <div className="popup-overlay-MindMap">
                <div className="popup-MindMap">
                    <button
                    className="close-button-MindMap"
                    onClick={() => setModulePopup(false)}
                    style={{ float: "right", marginBottom: "1rem" }}
                    >
                    Close
                    </button>

                    <h3 className="popup-header-MindMap">Add New Module</h3>
                    {/* <input
                    className="input-MindMap"
                    type="text"
                    placeholder="Module ID"
                    value={newModule.module_id}
                    onChange={(e) => setNewModule({ ...newModule, module_id: e.target.value })}
                    style={{ display: "block", marginBottom: "1rem" }}
                    /> */}
                    <input
                    className="input-MindMap"
                    type="text"
                    placeholder="Heading/Label"
                    value={newModule.label}
                    onChange={(e) => setNewModule({ ...newModule, label: e.target.value })}
                    style={{ display: "block", marginBottom: "1rem" }}
                    />
                    <textarea
                    className="textarea-MindMap"
                    placeholder="Description"
                    value={newModule.description}
                    onChange={(e) =>
                        setNewModule({ ...newModule, description: e.target.value })
                    }
                    style={{ display: "block", marginBottom: "1rem" }}
                    ></textarea>
                    <button className="add-module-button-MindMap" onClick={addModule}>Add Module</button>
                </div>
                </div>
            )}

        </div>
        </div>
        }



    {/* //! The Map Code */}

    <ReactFlow
        // nodes={displayedNodes}
        // edges={displayedEdges}
        nodes={Array.isArray(displayedNodes) ? displayedNodes : []}
        edges={Array.isArray(displayedEdges) ? displayedEdges : []}
        onEdgeClick={(event, edge) => handleEdgeClick(edge)} 
        edgeoptions={{
            style: { strokeWidth: 5 }, 
        }}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onNodeClick={onNodeClick}
        onConnect={onConnect} 
        fitView
        // nodeTypes={nodeTypes}
    >
        <Controls />
        <Background />
    </ReactFlow>

  {isPopupOpen && (
    <div className="popup-overlay">
      <div className="popup">
        <div className="popup-header">
            {editMode ? 'Edit Current Node' : 'Add New Node'}
            &nbsp;
            <button
                className="cancel"
                onClick={() => {
                    setIsPopupOpen(false)
                    setViewKnowledgeStatus(false)
                    setActiveManagePopupNavigation("new_node")
                }}
            >
                Cancel
            </button>
        </div>

        <button onClick={()=>{console.log("knowledgeData", knowledgeData)}}>knowledge</button>

        <div>
            <button
            onClick={()=>{
                setActiveManagePopupNavigation("new_node")
                setEditMode(false)
                setNewNodeData({
                    name: '',
                    description: '',
                    type: 'global', 
                    description_status: 'visible'
                });
            }}
            >
                {
                    activeManagePopupNavigation === "new_node" ?
                    <div>
                        <b>Add new node</b>
                    </div>
                    :
                    <div>
                        Add new node    
                    </div>
                }
            </button>

            <button
            onClick={()=>{
                setActiveManagePopupNavigation("edit_node")
                setEditMode(true)
                setNewNodeData({
                    name: selectedNode.data.name || '',
                    description: selectedNode.data.description || '',
                    type: selectedNode.data.type || '', 
                    description_status: selectedNode.data.description_status || ''
                });
            }}
            >
                {
                    activeManagePopupNavigation === "edit_node" ?
                    <div>
                        <b>Edit node</b>
                    </div>
                    :
                    <div>
                        Edit node   
                    </div>
                }
            </button>

            <button
            onClick={()=>{
                setActiveManagePopupNavigation("knowledge")
                setViewKnowledgeStatus(true)

                if (
                    !trackKnowledgeDataStatus || 
                    trackKnowledgeDataStatus.brain_id !== activeBrainId || 
                    trackKnowledgeDataStatus.node_id !== activeNodeId
                ) {
                    handleBackend_LoadKnowledgeData();
                }

            }}
            >
                {
                    activeManagePopupNavigation === "knowledge" ?
                    <div>
                        <b>Knowledge</b>
                    </div>
                    :
                    <div>
                        Knowledge
                    </div>
                }
            </button>

            <button
            onClick={()=>{
                setActiveManagePopupNavigation("manage")
                setViewKnowledgeStatus(true)
            }}
            >
                {
                    activeManagePopupNavigation === "manage" ?
                    <div>
                        <b>Manage</b>
                    </div>
                    :
                    <div>
                        Manage
                    </div>
                }
            </button>

        </div>


        {
            (activeManagePopupNavigation === "new_node" || activeManagePopupNavigation === "edit_node")
            &&
        
            <div className="popup-form">
            <label>
                Node Name <br></br>
                <input
                type="text"
                name="name"
                value={newNodeData.name}
                onChange={handleInputChange}
                />
            </label>
            <label>
                Description <br></br>
                <textarea
                type="text"
                name="description"
                className='textarea-description-knowledge_2'
                value={newNodeData.description}
                onChange={handleInputChange}
                />
            </label>
            <label>
                Global/Local Type 
                <select
                name="type"
                value={newNodeData.type}
                onChange={handleInputChange}
                >
                {selectedNode?.data?.type === 'local' && !editMode ? (
                    <>
                    <option value="local">Local</option>
                    </>
                ) : (
                    <>
                        <option value="global">Global</option>
                        <option value="local">Local</option>
                    </>
                )}
                </select>
            </label>

            <label>
                Description Status
                <select
                name="description_status"
                value={newNodeData.description_status}
                onChange={handleInputChange}
                >
                    <option value="visible">Visible</option>
                    <option value="hidden">Hidden</option>
                </select>
            </label>

            </div>
        }

        {
            (activeManagePopupNavigation === "new_node" || activeManagePopupNavigation === "edit_node") &&
            
            <div className="popup-actions">
            {/* <button
                className="cancel"
                onClick={() => {
                    setIsPopupOpen(false)
                    setViewKnowledgeStatus(false)
                }}
            >
                Cancel
            </button> */}
            {editMode ? (
                <button className="save" onClick={handleSaveNode}>
                Save Changes
                </button>
            ) : (
                <button className="add" onClick={handleAddNode}>
                Add Node
                </button>
            )}

            <button className="delete-button" onClick={handleDeleteNode}>
                Delete Node
            </button>

            </div>
        }

        {
            activeManagePopupNavigation === "manage" &&
            <div>

                {!localView && (
                    <button className="@view-local" 
                    onClick={()=>{
                        handleViewOnlyLocal()
                        setIsPopupOpen(false)
                        setViewKnowledgeStatus(false)
                        setActiveManagePopupNavigation("manage")
                    }}
                    >
                    View Only Local
                    </button>
                )}
                {localView && (
                    <button
                    className="@remove-local-view"
                    onClick={handleRemoveLocalView}
                    >
                    Remove Local View
                    </button>
                )}

                &nbsp; &nbsp;        

                {!hideParentsView && (
                    <button className="hide-parents" 
                    onClick={()=>{
                        handleHideParents()
                        setIsPopupOpen(false)
                        setViewKnowledgeStatus(false)
                        setActiveManagePopupNavigation("manage")
                    }}
                    >
                    Hide All Parents
                    </button>
                )}
                {hideParentsView && (
                    <button
                    className="remove-hide-parents"
                    onClick={handleRemoveHideParents}
                    >
                    Remove Hide Parents
                    </button>
                )}

            </div>
        }

        {/*//! - knowledge */}

        {   activeManagePopupNavigation === "knowledge" &&
            viewKnowledgeStatus &&    
            <div>
                <button
                    onClick={()=>{setAddKnowledgeStatus(true)}}
                >
                    Add Knowledge +
                </button>
                {
                addKnowledgeStatus && (
                    <div className="popup-overlay-knowledge">
                    <div className="popup-knowledge">
                        {/* <input
                        className="input-id-knowledge"
                        type="number"
                        placeholder="Id"
                        value={newKnowledge.k_id}
                        onChange={(e) =>
                            setNewKnowledge({ ...newKnowledge, k_id: e.target.value })
                        }
                        /> */}
                        <input
                        className="input-name-knowledge"
                        type="text"
                        placeholder="Heading/Label"
                        value={newKnowledge.name}
                        onChange={(e) =>
                            setNewKnowledge({ ...newKnowledge, name: e.target.value })
                        }
                        />
                        <textarea
                        className="textarea-description-knowledge"
                        placeholder="Description"
                        value={newKnowledge.description}
                        onChange={(e) =>
                            setNewKnowledge({ ...newKnowledge, description: e.target.value })
                        }
                        ></textarea>

                        <div className="button-group-knowledge">
                        <button
                            className="cancel-button-knowledge"
                            onClick={() => {
                            setAddKnowledgeStatus(false);
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className="save-button-knowledge"
                            onClick={addKnowledge}
                            disabled={!newKnowledge.name}
                        >
                            Save
                        </button>
                        </div>
                    </div>
                    </div>
                )}
            </div>
        }


        {
        (activeManagePopupNavigation === "knowledge" && viewKnowledgeStatus) && (
            <div className="knowledge-list-container-knowledge">
            {knowledgeData && knowledgeData.length > 0 &&
                knowledgeData.map((data, index) => {
                if (
                    data.r_module_id === selectedModule &&
                    data.r_brain_id === activeBrainId &&
                    data.r_node_id === activeNodeId
                ) {
                    return (
                    <button
                        className="knowledge-item-button-knowledge"
                        key={index}
                        onClick={() => {
                        setSaveContentStatus(false);
                        setActiveKnowledgePageData({
                            // Store the current row clicked
                            k_id: data.k_id, // Assuming `id` uniquely identifies the knowledge
                            name: data.name,
                            description: data.description,
                            information: data.information,
                            r_module_id: data.r_module_id,
                            r_brain_id: data.r_brain_id,
                            r_node_id: data.r_node_id,
                        });
                        openPopup();
                        }}
                    >
                        <div className="knowledge-name-knowledge">{data.name}</div>
                        <div className="knowledge-description-knowledge">{data.description}</div>
                        <button
                        onClick={(event) => {
                            event.stopPropagation();
                            handleBackend_deleteKnowledge(data.k_id);
                        }}
                        >
                            Delete
                        </button>
                    </button>
                    );
                }
                })}
            </div>
        )}



        {/* <button
        onClick={()=>{console.log("activeKnowledgePageData", activeKnowledgePageData)}}
        >
            <b>Print active knowledge page data.</b>
        </button> */}

        {   activeManagePopupNavigation === "knowledge" &&
            activeKnowledgePageData && 
            <div className="popup-overlay-content-knowledge">
                <div className="popup-content-knowledge">
                    <TipTap 
                    activeKnowledgePageData={activeKnowledgePageData}
                    setActiveKnowledgePageData={setActiveKnowledgePageData}
                    saveContentStatus={saveContentStatus}
                    setSaveContentStatus={setSaveContentStatus}
                    handleSaveKnowledgeContent={handleSaveKnowledgeContent}
                    closePopup={closePopup}
                    />
                </div>
            </div>    
        }

        
      </div>
    </div>
  )}

    <ToastContainer />

</div>

  );
};

export default MindMap;

