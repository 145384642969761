import React, { useState } from 'react'

//
import './Journal_Home.scss'

//
import Journal_Table from './Other Components/Journal_Table';
import Journal_Calendar from './Other Components/Journal_Calendar';
import Journal_Graph from './Other Components/Journal_Graph';

function Journal_Home() {


    const[activeNavigation_1, setActiveNavigation_1] = useState("calendar");

    const [tables, setTables] = useState([]);

  return (
    <div>
        
        <div
        className="navigation-buttons"
        >
            <button
            onClick={()=>{setActiveNavigation_1("calendar")}}
            >
                {
                    activeNavigation_1 === "calendar" ?
                    <div>
                        <b>Calendar</b>
                    </div>
                    :
                    <div>
                        Calendar
                    </div>
                }
                
            </button>
            
            <button
            onClick={()=>{setActiveNavigation_1("table")}}
            >
                {
                    activeNavigation_1 === "table" ?
                    <div>
                        <b>Table</b>
                    </div>
                    :
                    <div>
                        Table
                    </div>
                }
            </button>
            
            <button
            onClick={()=>{setActiveNavigation_1("graph")}}
            >
                {
                    activeNavigation_1 === "graph" ?
                    <div>
                        <b>Graph</b>
                    </div>
                    :
                    <div>
                        Graph
                    </div>
                }
            </button>
        </div>

        <div>
            {
                activeNavigation_1 === "calendar" && 
                <div>
                    <Journal_Calendar />
                </div>
            }

            {
                activeNavigation_1 === "table" && 
                <div>
                    <Journal_Table 
                    tables={tables} 
                    setTables={setTables}
                    />
                </div>
            }

            {
                activeNavigation_1 === "graph" && 
                <div>
                    <Journal_Graph
                    tables={tables}
                    setTables={setTables}
                    />
                </div>
            }
        </div>

    </div>
  )
}

export default Journal_Home