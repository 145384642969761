import React, { useState } from 'react';
import Chart from 'chart.js/auto';  // Chart.js for graphs
import { Line, Bar, Radar, Pie } from 'react-chartjs-2';  // Graph types

function Journal_Graph() {
  // Initial tables state (this would be dynamic or fetched from a backend)
  const [tables] = useState([
    {
      id: "01",
      name: "Class",
      description: "Class Name",
      columns: ["name", "column", "start time", "end time"],
      rows: [
        { name: "one", column: "", "start time": "4:00 pm", "end time": "5:00 pm" },
        { name: "two", column: "", "start time": "2:00 am", "end time": "3:00 am" },
      ]
    },
    {
      id: "02",
      name: "School List",
      description: "School list name",
      columns: ["name", "rank", "date"],
      rows: [
        { name: "01", rank: "54", date: "1:00 am, 4 july 2024" }
      ]
    }
  ]);

  // State to hold created graphs
  const [graphs, setGraphs] = useState([]);

  // State for the modal input
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTable, setSelectedTable] = useState(null);
  const [graphType, setGraphType] = useState('line');
  const [graphDetails, setGraphDetails] = useState({
    id: '',
    name: '',
    description: '',
  });

  // Handle graph creation
  const handleSaveGraph = () => {
    const newGraph = {
      id: Date.now().toString(),
      ...graphDetails,
      tableId: selectedTable.id,
      graphType,
    };
    setGraphs([...graphs, newGraph]); // Add new graph to the state
    setModalOpen(false); // Close modal
  };

  // Handle table selection
  const handleSelectTable = (tableId) => {
    const table = tables.find(t => t.id === tableId);
    setSelectedTable(table);
  };

  // Graph data structure (for simplicity, we assume the columns are numbers for Bar/Line charts)
  const getGraphData = (graphType, table) => {
    const labels = table.rows.map(row => row.name);
    const data = table.rows.map(row => parseInt(row.rank || 0, 10)); // Example using "rank" column
    
    return {
      labels,
      datasets: [
        {
          label: table.name,
          data,
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1,
        }
      ]
    };
  };

  // Render charts
  const renderGraph = (graph) => {
    const table = tables.find(t => t.id === graph.tableId);
    const graphData = getGraphData(graph.graphType, table);

    switch (graph.graphType) {
      case 'line':
        return <Line data={graphData} />;
      case 'bar':
        return <Bar data={graphData} />;
      case 'radar':
        return <Radar data={graphData} />;
      case 'pie':
        return <Pie data={graphData} />;
      default:
        return null;
    }
  };

  return (
    <div className="graph-container">
      <button onClick={() => setModalOpen(true)}>Add Graph</button>

      {modalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>Create a Graph</h2>
            <input
              type="text"
              placeholder="ID"
              value={graphDetails.id}
              onChange={(e) => setGraphDetails({ ...graphDetails, id: e.target.value })}
            />
            <input
              type="text"
              placeholder="Name"
              value={graphDetails.name}
              onChange={(e) => setGraphDetails({ ...graphDetails, name: e.target.value })}
            />
            <input
              type="text"
              placeholder="Description"
              value={graphDetails.description}
              onChange={(e) => setGraphDetails({ ...graphDetails, description: e.target.value })}
            />

            {/* Table selection */}
            <button onClick={() => setModalOpen(false)}>
              Select Table
            </button>
            <div className="table-selection">
              {tables.map((table) => (
                <button key={table.id} onClick={() => handleSelectTable(table.id)}>
                  {table.name}
                </button>
              ))}
            </div>

            {/* Graph Type Selection */}
            <select onChange={(e) => setGraphType(e.target.value)} value={graphType}>
              <option value="line">Line</option>
              <option value="bar">Bar</option>
              <option value="radar">Radar</option>
              <option value="pie">Pie</option>
            </select>

            <button onClick={handleSaveGraph}>Save Graph</button>
          </div>
        </div>
      )}

      <div className="graphs">
        {graphs.map((graph) => (
          <div key={graph.id} className="graph">
            <h3>{graph.name}</h3>
            <p>{graph.description}</p>
            {renderGraph(graph)}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Journal_Graph;
