import React, { useEffect, useState } from "react";

import "./Notes_Tool.scss";

import { Helmet } from 'react-helmet'
import TestingLexical from "./TestingLexical";


export const TipTap = (props) => {

  const{
    activeKnowledgePageData, setActiveKnowledgePageData, saveContentStatus, setSaveContentStatus, handleSaveKnowledgeContent, closePopup
  } = props;

  const[editKnowledgeStatus, setEditKnowledgeStatus] = useState();

  return (
    <div>
      <Helmet>   

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=Assistant:wght@400&family=Comfortaa:wght@300&family=Finlandica&family=Karla:wght@300&family=Kodchasan:wght@200&family=Lato:wght@300&family=Mitr:wght@300&family=Montserrat:wght@300&family=Nanum+Gothic&family=Nunito+Sans:opsz,wght@6..12,300&family=Oxygen&family=Poppins:wght@300&family=Quicksand:wght@300&family=Rubik&family=Satisfy&family=Space+Grotesk&family=Work+Sans:wght@300&family=Ysabeau+Office:wght@200&display=swap" rel="stylesheet" />

      <link href="https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&display=swap" rel="stylesheet" />

      </Helmet>

      <div className="text-editor">
        {/* <button onClick={()=>{console.log("activeKnowledgePageData", activeKnowledgePageData)}}>activeKnowledgePageData</button> */}

        
        <div>
          <button
          onClick={()=>{
            setActiveKnowledgePageData(null)
            closePopup()
          }}
          >
            Close
          </button> &nbsp;
          <button
          onClick={()=>{setEditKnowledgeStatus(true)}}
          >
            Edit
          </button> &nbsp;
          {
            saveContentStatus &&
            <button
            onClick={()=>{handleSaveKnowledgeContent(activeKnowledgePageData)}}
            >
              Save Data
            </button>
          }
        </div>

        {
          editKnowledgeStatus && 
          <div className="popup-overlay-knowledge">
          <div className="popup-knowledge">
            <button
            onClick={()=>{setEditKnowledgeStatus(false)}}
            >
              close
            </button>
            <div>
              <input
                type="text"
                className="input-id-knowledge"
                value={activeKnowledgePageData.name}
                onChange={(e) => {
                  setActiveKnowledgePageData((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }));
                  setSaveContentStatus(true); // Ensure this is inside the arrow function
                }}
              />
            </div>
            
            <div>
              <i>
              <textarea
                className="input-id-knowledge"
                value={activeKnowledgePageData.description}
                onChange={(e) => {
                  setActiveKnowledgePageData((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                  setSaveContentStatus(true);
                }}
              />
              </i>
            </div>
          </div>
          </div>
        }  
        
        <TestingLexical
          activeKnowledgePageData={activeKnowledgePageData}
          setActiveKnowledgePageData={setActiveKnowledgePageData}
          saveContentStatus={saveContentStatus}
          setSaveContentStatus={setSaveContentStatus}
          handleSaveKnowledgeContent={handleSaveKnowledgeContent}
        />
      </div>
    </div>
  );
};

export default TipTap;
 