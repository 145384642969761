import React from 'react'

//importing scss
import './Home.scss'

//
import { useNavigate } from 'react-router-dom'

function Home() {

  const navigate = useNavigate();

  const handleAliveClick = () => {
    navigate('/mind-map')
  }

  const handleJournalClick = () => {
    navigate('/daily-journal')
  }

  return (
    <div
    className='container_p-Home'
    >
      <div>
        <button
          className='button-1-Home'
          onClick={()=>{handleAliveClick()}}
          >
          Mind Map
        </button>
      </div>
&nbsp; &nbsp;
      <div>
        <button
          className='button-1-Home'
          onClick={()=>{handleJournalClick()}}
        >
          Journal
        </button>
      </div>

    </div>
  )
}

export default Home